import React from "react";
import Backdrop from "@mui/material/Backdrop";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 1500,
    color: "#fff",
    // position: "absolute",
    width: "100%",
    minHeight: "100vh",
  },
}));

const Loader = (props) => {
  const classes = useStyles();
  return (
    <div>
      {props.isLoading && (
        <Backdrop className={classes.backdrop} open style={props.style}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
};

export default Loader;
