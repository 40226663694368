import { url } from "./configuration";
import { Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

export const loginURL = url + "/v1/login";
export const verifiyUserSession = url + "/v1/loginsession";
export const uploadURL = url + "/v1/upload";
export const historyURL = url + "/v1/history";
export const logoutURL = url + "/v1/logout";
export const dReportSummaryURL = url + "/v1/d-report-summary?testId=";
export const dErrorSummaryURL = url + "/v1/d-error-summary?testId=";
export const dTop10SummaryURL = url + "/v1/d-top10-summary?testId=";
export const dTotalSummaryURL = url + "/v1/d-total-summary?testId=";
export const dAggrateSummaryURL = url + "/v1/d-aggrate-summary?testId=";
export const chartTotalThreadURL = url + "/v1/chart-totalThread?testId=";
export const chartReqpersecURL = url + "/v1/chart-reqpersec?testId=";
export const respersecURL = url + "/v1/chart-respersec?testId=";
export const chartErrorpersecURL = url + "/v1/chart-errorpersec?testId=";
export const chartSuccesserrorURL = url + "/v1/chart-successerror?testId=";
export const chartSentNetworkURL = url + "/v1/chart-sentnetwork?testId=";
export const chartTranswiserequestURL =
  url + "/v1/chart-transwiserequest?testId=";
export const threadGrouplistURL = url + "/v1/threadGrouplist?testId=";
export const chartTranswiseresponseURL =
  url + "/v1//chart-transwiseresponse?testId=";

// endopoint for comparison chart
export const compChartTotalThreadURL =
  url + "/v1/comp-chart-totalThread?testId1=";
export const compChartRespersecURL = url + "/v1/comp-chart-respersec?testId1=";
export const compChartReqpersecURL = url + "/v1/comp-chart-reqpersec?testId1=";
export const compChartErrorpersecURL =
  url + "/v1/comp-chart-errorpersec?testId1=";
export const compChartSentnetworkURL =
  url + "/v1/comp-chart-sentnetwork?testId1=";

export const getDate = (timestamp, timeZone) => {
  timestamp = parseInt(timestamp);

  if (timeZone) {
    let currentTimeZone = new Date().getTimezoneOffset();
    if (currentTimeZone < 0) {
      currentTimeZone = Math.abs(currentTimeZone);
    } else {
      currentTimeZone = 0 - Math.abs(currentTimeZone);
    }
    timestamp =
      timestamp - currentTimeZone * 60 * 1000 + timeZone * 60 * 60 * 1000;
  }
  let parseValue = new Date(timestamp).toString();
  let newTime = parseValue.substring(16, 21);
  let returnDate = {
    year: parseValue.substring(11, 15),
    date: parseValue.substring(8, 10),
    month: parseValue.substring(4, 7),
    time:
      newTime.split(":")[0] === "12"
        ? parseInt(newTime.split(":")[0]) +
          ":" +
          newTime.split(":")[1] +
          " " +
          (parseInt(parseInt(newTime.split(":")[0]) / 12) != 1 ? "AM" : "PM")
        : (parseInt(newTime.split(":")[0]) % 12) +
          ":" +
          newTime.split(":")[1] +
          " " +
          (parseInt(parseInt(newTime.split(":")[0]) / 12) != 1 ? "AM" : "PM"),
  };
  return returnDate;
};
export const getDateTime = (timestamp, timeZone) => {
  //return format 2024-05-23T13:17
  if (!timestamp) {
    return;
  }
  timestamp = parseInt(timestamp);
  if (timeZone) {
    let currentTimeZone = new Date().getTimezoneOffset();
    if (currentTimeZone < 0) {
      currentTimeZone = Math.abs(currentTimeZone);
    } else {
      currentTimeZone = 0 - Math.abs(currentTimeZone);
    }
    timestamp =
      timestamp - currentTimeZone * 60 * 1000 + timeZone * 60 * 60 * 1000;
  }
  let parseValue = new Date(timestamp).toString();
  let newTime = parseValue.split(" ")[4].split(":");
  let month = parseInt(new Date(timestamp).getMonth()) + 1;
  if (month < 10) {
    month = "0" + month;
  }

  let retunValue =
    parseValue.substring(11, 15) +
    "-" +
    month +
    "-" +
    parseValue.substring(8, 10) +
    "T" +
    newTime[0] +
    ":" +
    newTime[1];

  return retunValue;
};

// show notification msz
export const showNotificationMsz = (message, messageType, time) => {
  Store.addNotification({
    title: "",
    message: `${message}`,
    type: `${messageType}`,
    insert: "top-center",
    container: "top-center",
    dismiss: {
      duration: time || 3000,
      onScreen: true,
    },
  });
};
