import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RoutesAuthenticated from "./RoutesAuthenticated";
import UnAuthRoute from "./UnAuthRoute";
import { connect } from "react-redux";
import { UserData, islogin } from "../Components/Common/commonConst";
import { verifiyUserSession } from "../Components/Common/comman";
import { apihandler } from "../Components/Common/apicaller";
import Loader from "../Components/Common/Loader";

const ValidateFlow = (props) => {
  const [splashOpen, setSplashOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    validateUserData();
  }, []);

  const validateUserData = async () => {
    let { data, status } = await apihandler({}, verifiyUserSession, "get");

    if (status === 1) {
      await props.dispatch({
        type: UserData,
        value: data,
      });
      await props.dispatch({
        type: islogin,
        value: true,
      });
      setSplashOpen(false);
    } else {
      setSplashOpen(false);
    }
  };
  if (splashOpen) {
    return <Loader isLoading={splashOpen} />;
  } else {
    return (
      <>
        {props.ISLOGIN ? (
          <RoutesAuthenticated
            {...props}
            navigate={navigate}
            location={location}
          />
        ) : (
          <UnAuthRoute {...props} navigate={navigate} location={location} />
        )}
      </>
    );
  }
};

const mapStateToProps = (state, ownProps) => ({
  ISLOGIN: state[islogin] || false,
  USERDATA: state[UserData] || false,
});
export default connect(mapStateToProps)(ValidateFlow);
