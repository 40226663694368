import axios from "axios";

export const apihandler = async (body, url, method, isFile, Cookies) => {
  try {
    let payload = !isFile ? JSON.stringify(body) : body;
    let config = {
      method,
      url,
      headers: {
        "Content-Type": !isFile ? "application/json" : "multipart/form-data",
      },
      data: payload,
    };

    let responseToUser = { data: "", status: 0, message: "" };

    await axios(config)
      .then((response) => {
        responseToUser = response.data;
      })
      .catch((error) => {
        console.log("error in request", error);
      });

    return responseToUser;
  } catch (error) {
    return null;
  }
};
