const initialState = {
  islogin: false,
  userid: "",
  ISLOADING: false,
};

export default (state = initialState, action) => {
  try {
    let data = {};

    data[action.type] = action.value;

    return { ...state, ...data };
  } catch (error) {
    return state;
  }
};
