import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Loading from "../Components/Loading/Loading";

const Dashboard = React.lazy(() => import("../Components/Dashboard/Dashboard"));
const ReportDetail = React.lazy(() =>
  import("../Components/ReportDetails/ReportDetail")
);
const CompareTestReport = React.lazy(() =>
  import("../Components/Dashboard/CompareTestReport")
);

const RoutesAuthenticated = (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<Dashboard {...props} />} />
        <Route path="/detailed-report" element={<ReportDetail {...props} />} />
        <Route
          path="/compare-report"
          element={<CompareTestReport {...props} />}
        />
      </Routes>
    </Suspense>
  );
};

export default RoutesAuthenticated;
